<template>
  <div class="affiliatePageMember">
    <div class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"
          ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-1
            px-2
            xtarget
            col-lotto
            d-flex
            flex-row
            mb-1
            pb-0
          "
        >
          <div class="lotto-title">
            <h4><i class="fas fa-thumbs-up"></i> แนะนำเพื่อน</h4>
          </div>
        </div>

        <div class="row px-0 mb-1 mx-0">
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="far fa-handshake" />
              </div>
              ภาพรวม
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/members"
              class="
                btn-af btn btn-outline-primary btn-block
                d-flex
                flex-column
                active
              "
            >
              <div class="affiliate-icon">
                <i class="fas fa-chalkboard-teacher" />
              </div>
              ที่สมัคร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/revenue"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-money-check-alt" />
              </div>
              รายได้
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/withdraw"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-hand-holding-usd" />
              </div>
              ถอนรายได้
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-2
            mb-1
            xtarget
            col-lotto
          "
        >
          <h4><i class="fas fa-users"></i> สมาชิกที่แนะนำ</h4>
        </div>
        <div class="bg-white text-secondary shadow-sm rounded py-2 px-1 mb-5">
          <table
            class="datatable table table-hover table-striped table-sm"
            id="invitemembers"
          >
            <thead>
              <tr>
                <th class="datetime text-left">
                  <span>วัน-เวลา</span><span>ที่สมัคร</span>
                </th>
                <th>ยูสเซอร์เนม</th>
                <th class="totalbet text-right"><span>แทงทั้งหมด</span></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="listFriend.length > 0">
                <tr class="" v-for="(item, index) in pageOfItems" :key="index">
                  <td class="datetime text-left">{{ item.dateRegister }}</td>
                  <td>{{ filterName(item.userName) }}</td>
                  <td class="totalbet text-right">{{ numberWithCommas(item.commitionValue) }}</td>
                </tr>
              </template>
               <tr v-else>
                <td colspan="3" class="text-center">
                  <span class="text-primary" v-if="listFriend.length === 0">ไม่มีรายการ</span>
                </td>
              </tr>
            </tbody>
          </table>
          <jw-pagination :items="listFriend" @changePage="onChangePage"></jw-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { findAllFriendAffiliateService } from '@/services'
import JwPagination from 'jw-vue-pagination'
// Test pagination
const exampleItems = [...Array(50).keys()].map(i => ({ dateRegister: (i + 1), userName: 'Item ' + (i + 1), commitionValue: 'Flintstone' }))
export default {
  components: { JwPagination },
  async created () {
    this.setLoading(true)
    const resp = await findAllFriendAffiliateService()
    if (resp.status_code === 'SUCCESS') {
      this.listFriend = resp.data.listFriend
    }
    this.setLoading(false)
  },
  data: () => {
    return {
      listFriend: [],
      pageOfItems: [],
      exampleItems
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    onChangePage (pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    numberWithCommas (n) {
      const x = Number(n)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    filterName (val) {
      const strAr = val.split('')
      let ret = ''
      strAr.forEach((value, index) => {
        if (index > 1 && index < (val.length - 2)) {
          ret = ret + '*'
        } else {
          ret = ret + value
        }
      })
      return ret
    }
  }
}
</script>

<style>
</style>
